import React, { useContext } from "react";
import { AppStateContext } from "../state/AppStateContext";
import Layout from "./common/Layout";
import { Heading, Paragraph, suomifiDesignTokens as tokens, Text } from "suomifi-ui-components";
import LoadingModal from "./modals/LoadingModal";
import { Trans, useTranslation } from "react-i18next";
import XbrlGlDocumentsTable from "./XbrlGlDocumentsTable";

const Home: React.FC = () => {
  const appContext = useContext(AppStateContext);
  const { t } = useTranslation(["translation"]);
  
  return (
    <Layout title={""}>
      <Heading variant="h2" style={{margin: `${tokens.spacing.m} 0`}}>{t("xbrl.components.XbrlGlDocumentsTable.heading")}</Heading>
      { appContext.xbrlGlDocuments && <XbrlGlDocumentsTable/>}
      <Heading variant="h2" style={{margin: `${tokens.spacing.m} 0`}}>{t("xbrl.components.XbrlGlApi.heading")}</Heading>
      <Paragraph>
        <Text>
          <Trans
            i18nKey="xbrl.components.XbrlGlApi.description"
            components={{
              link1: <a href="https://xbrl-gl-api.minisuomi.net/api/swagger/ui" target="_blank">XBRL-dokumenttien API-rajapinta</a>
            }}
          />
        </Text>
      </Paragraph>
      <LoadingModal isOpen={appContext.isLoadingApp} contentLabel={"Loading"} message={"Loading content..."}/>
    </Layout>
  );
};

export default Home;
