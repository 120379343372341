import React from "react";
import { FlexCol, FlexRow } from "./common/CommonStyles";
import Card from "./common/Card";
import { Box } from "@material-ui/core";


const DemoFormView: React.FC = () => {

  return (
    <FlexCol>
      <div>
      </div>
      <FlexRow css={{
        "& > *": {
          boxSizing: "border-box",
          minHeight: "150px"
        }
      }}>
        <Card
          title="Testilomake"
        >
          <p>Vastaa kyselyyn:</p>
          <Box display="flex" justifyContent="center">
          <iframe width="800" height="600" src="https://link.webropolsurveys.com/S/4FF1F19AA0ECD4F0"></iframe>
          </Box>
        </Card>
      </FlexRow>
    </FlexCol>
  );
}

export default DemoFormView;