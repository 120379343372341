import React, { useContext } from "react";
import { AppStateContext } from "../state/AppStateContext";
import { Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tooltip } from "@material-ui/core";
import { Button, suomifiDesignTokens as tokens } from "suomifi-ui-components";
import log from "loglevel";
import TablePaginationActions from "./common/TablePaginationActions";
import { useTranslation } from "react-i18next";
import * as XbrlApi from "../api/XbrlApi";
import Modal from "./common/Modal";
import ViewXbrlGlDocumentModal from "./modals/ViewXbrlGlDocumentModal";

const XbrlGlDocumentsTable: React.FC = () => {
  const appContext = useContext(AppStateContext);
  const logger = log.getLogger(XbrlGlDocumentsTable.name);
  const { t } = useTranslation(["translation"]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [modalDocId, setModalDocId] = React.useState<string|undefined>(undefined);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - appContext.xbrlDocList.length) : 0;

  return (
    <TableContainer component={Paper} style={{minWidth: "800px"}}>
      <Table aria-label="simple table">
        <colgroup>
          <col width="10%" />
          <col width="10%" />
          <col width="60%" />
          <col width="20%" />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell>{t("xbrl.models.XbrlGlDocument.companyCode")}</TableCell>
            <TableCell align="right">{t("xbrl.models.XbrlGlDocument.entriesType")}</TableCell>
            <TableCell align="right">{t("xbrl.models.XbrlGlDocument.entriesComment")}</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? appContext.xbrlDocList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : appContext.xbrlDocList
          ).map((doc, index) => (
            <TableRow
              key={index}
            >
              <TableCell component="th" scope="row">{doc.companyCode}</TableCell>
              <TableCell align="right">{doc.entriesType}</TableCell>
              <TableCell align="right">{doc.entriesComment ?? doc.originalFileName}</TableCell>
              <TableCell align="center">
                <Tooltip disableFocusListener disableTouchListener placement='top' title={doc.originalFileName} >
                  <Button style={{marginRight: tokens.spacing.xs}} onClick={() => XbrlApi.DownloadXbrlGlDocument(doc)}>{t("xbrl.components.XbrlGlDocumentsTable.download")}</Button>
                </Tooltip>
                <Tooltip disableFocusListener disableTouchListener placement='top' title={doc.originalFileName} >
                  <Button style={{marginRight: tokens.spacing.xs, marginTop: tokens.spacing.xxs}} onClick={() => setModalDocId(doc.id)}>{t("xbrl.components.XbrlGlDocumentsTable.open")}</Button>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={5} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              count={appContext.xbrlDocList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>

      <Modal isOpen={modalDocId !== undefined} styleContent={{minWidth: "600px"}} closeModal={() => setModalDocId(undefined)} closeOnEscOrOverlayClick={true}>
        { modalDocId && <ViewXbrlGlDocumentModal docId={modalDocId} />}
      </Modal>
    </TableContainer>
  );
};

export default XbrlGlDocumentsTable;