import React, { useEffect, useState, PropsWithChildren } from "react";
import log from "loglevel";
import * as XbrlApi from "../api/XbrlApi";
import _ from "lodash";
import { XbrlGlDocument } from "../models/OmaXModels";

interface AppState {
  isLoadingApp: boolean;
  xbrlGlDocuments: {[companyId: string]: XbrlGlDocument[]};
  xbrlDocList: XbrlGlDocument[];
}

export type AppStateType = AppState & {
  // Functions
  loadAppStateAsync: () => Promise<void>;
  getXbrlGlDocumentsAsync: () => Promise<{[companyId: string]: XbrlGlDocument[]}>;
}

export const DefaultAppState: AppState = {
  isLoadingApp: true,
  xbrlGlDocuments: {},
  xbrlDocList: []
}

export const DefaultAppStateContext: AppStateType = {
  ...DefaultAppState,
  loadAppStateAsync: () => new Promise<void>(resolve => resolve()),
  getXbrlGlDocumentsAsync: () => new Promise<{[companyId: string]: XbrlGlDocument[]}>(resolve => resolve({}))
}

// AppContext with default values. AppContextProvider replaces defaults with the real values.
export const AppStateContext = React.createContext<AppStateType>(DefaultAppStateContext);

// An alternative way to initialize a default AppStateContext would be the following.
// export const AppStateContext = React.createContext<AppStateType>({} as AppStateType);
// Here we skip creating dummy context by telling to Typescript compiler that an empty object is a valid AppStateType.
// If you do always make sure to only access the context inside of TodoContextProvider with useContext 
// then you can safely skip initialising AppStateType inside of createContext because that initial value 
// will never actually be accessed.

enum PromiseTypes {
  XBRL_GL_DOCUMENTS = "xbrlGlDocuments"
}

/**
 * AppContextProvider contains "public" getter methods and "private" fetch methods e.g. getCompanyAsync and fetchCompany
 * When fetching data from APIs, ongoing request promises are stored in the *promises* state. Getter methods (e.g. getCompanyAsync) 
 * return the existing request promise if one exists. If it does not exist, it calls fetch method (e.g. fetchCompany) 
 * to create a new request promise. The idea is to prevent concurrent requests to fetch the same data.
 * Methods that have a comment "public" are accessible outside of the AppContextProvider.
 * Methods that have a comment "private" are for internal use of the AppContextProvider only.
 */
const AppContextProvider: React.FC<PropsWithChildren> = ({children}) => {
  const [appState, setAppState] = useState<AppState>(DefaultAppState);
  // Contains ongoing request promises. To update state use methods addPromise and removePromise
  const [promises, setPromises] = useState<{[type: string]: Promise<any>}>({});
  const logger = log.getLogger(AppContextProvider.name);

  useEffect(() => {
    loadAppStateAsync();
  }, []);

  // private
  const addPromise = (type: string, promise: Promise<any>) => {
    setPromises(oldState => ({...oldState, [type]: promise}));
  }

  // private
  const removePromise = (type: string) => {
    setPromises(oldState => _.omit(oldState, type));
  }
  
  // public
  const loadAppStateAsync = async (): Promise<void> => {
    logger.debug("ACP loadAppStateAsync");
    // Reset necessary stuff in app state
    setAppState(oldState => ({...oldState, isLoadingApp: true}));
    // Fetch/load necessary stuff asynchronously
    return getXbrlGlDocumentsAsync()
    .then(() => {
      // Then update stuff into app state
      setAppState(oldState => ({...oldState, isLoadingApp: false}));
    });
  }

  // public
  const getXbrlGlDocumentsAsync = async (): Promise<{[companyId: string]: XbrlGlDocument[]}> => {
    logger.debug(`ACP getXbrlGlDocumentsAsync, promise`, appState.xbrlGlDocuments, promises[PromiseTypes.XBRL_GL_DOCUMENTS]);
    return promises[PromiseTypes.XBRL_GL_DOCUMENTS] ?? fetchXbrlGlDocuments();
  }

  // private
  const fetchXbrlGlDocuments = async (): Promise<{[companyId: string]: XbrlGlDocument[]}> => {
    logger.debug("ACP fetchXbrlGlDocuments");
    const promise = XbrlApi.GetXbrlGlDocuments()
    .then(res => {
      logger.debug("APC fetchXbrlGlDocuments res", res);
      if (res && res.data) {
        let documentsMap: { [id: string]: XbrlGlDocument[]} = {};
        const responseObjects: XbrlGlDocument[] = res.data;
        responseObjects.forEach(doc => {
          // If companyCode key does not exist in documentsMap, 
          // add companyCode with empty document array.
          if (!documentsMap[doc.companyCode]) {
            documentsMap = {...documentsMap, [doc.companyCode]: []}; 
          }
          documentsMap = {...documentsMap, [doc.companyCode]: [...documentsMap[doc.companyCode], doc]}
        });
        setAppState(oldState => ({...oldState, xbrlGlDocuments: documentsMap, xbrlDocList: responseObjects}));
        return documentsMap;
      }
      return {};
    })
    .catch(err => {
      logger.error("ACP Error in fetchXbrlGlDocuments", err);
      return {};
    })
    .finally(() => removePromise(PromiseTypes.XBRL_GL_DOCUMENTS));
    addPromise(PromiseTypes.XBRL_GL_DOCUMENTS, promise);
    return promise;
  }

  return (
    <AppStateContext.Provider value={{
      ...appState,
      loadAppStateAsync,
      getXbrlGlDocumentsAsync
    }}>
      {children}
    </AppStateContext.Provider>
  );
}

export default AppContextProvider;
