import axios, { AxiosResponse } from 'axios'
import { XbrlGlDocument } from '../models/OmaXModels';

const apiRootUrl = process.env.REACT_APP_XBRL_GL_API_URL || 'http://localhost:7071/api'
const apiKey = process.env.REACT_APP_XBRL_GL_API_KEY || "";

const api = axios.create({
  baseURL: apiRootUrl,
  headers: {
    "x-functions-key": apiKey
  },
})

export async function GetXbrlGlDocuments(): Promise<AxiosResponse<XbrlGlDocument[]>> {
  return api.get("/getDocumentMetadata");
}

export async function DownloadXbrlGlDocument(doc: XbrlGlDocument): Promise<void> {
  api({
    url: "/downloadDocument/" + doc.id,
    method: 'GET',
    responseType: "blob"
  })
  .then(res => {
    // create file link in browser's memory
    const href = URL.createObjectURL(res.data);

    // create "a" HTLM element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', doc.originalFileName);
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
}

export async function GetFile(docId: string): Promise<string> {
  return api({
    url: "/getDocument/" + docId,
    method: 'GET',
    responseType: "text"
  })
  .then(res => {
    return res.data;
  });
}
