import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useMatch } from "react-router-dom"
import { suomifiDesignTokens as tokens } from 'suomifi-ui-components'
import { AppStateContext } from '../../state/AppStateContext'

const Navigation: React.FC = () => {
  const appContext = useContext(AppStateContext);
  const { t } = useTranslation(["translation"]);

  return (
    <nav style={{
      display: 'flex',
      justifyContent: 'space-between'
    }}>
      <ul style={{
        display: 'flex',
        listStyle: 'none',
        margin: 0,
        padding: 0,
      }}>
        <li>
          <NavItem to="/" label={t("xbrl.navigation.frontpage")} />
        </li>
      </ul>
    </nav>
  )
}

const NavItem: React.FC<NavItemProps> = ({ to, label }: NavItemProps) => {
  const appContext = useContext(AppStateContext);

  let match = useMatch(to);

  return (
    <Link to={to} style={{
      color: tokens.colors.blackBase,
      borderBottom: match ? `4px solid ${tokens.colors.brandBase}` : `none`,
      lineHeight: 2.5,
      padding: `${tokens.spacing.xxs} 0`,
      marginRight: tokens.spacing.l,
      textDecoration: 'none',
      whiteSpace: 'nowrap',
    }}>
      {label}
    </Link>
  );
};

interface NavItemProps {
  to: string;
  label: string;
}

export default Navigation;
