import React, { useEffect, useState } from "react";
import styled from '@emotion/styled'
import * as XbrlApi from "../../api/XbrlApi";
import { useTranslation } from "react-i18next";

const XmlContainer = styled.div`
  ul {
    padding: revert
  }
`;

const ViewXbrlGlDocumentModal: React.FC<{docId: string}> = ({docId}) => {
  const { t } = useTranslation(["translation"]);

  const getLoadingElement = () => {
    const rootElement = document.createElement("div");
    const p = document.createElement("p");
    p.textContent=t("xbrl.components.ViewXbrlGlDocumentModal.loading");
    rootElement.appendChild(p);
    return rootElement;
  }

  const [doc, setDoc] = useState<HTMLDivElement>(getLoadingElement());

  useEffect(() => {
    let xmlD = "";
    XbrlApi.GetFile(docId)
    .then(xml => {
      xmlD = xml;
      return XbrlApi.GetFile("XBRLGL.xsl");
    })
    .then(xsl => {
      const rootElement = document.createElement("div");
      const parser = new DOMParser();
      const xsltProcessor = new XSLTProcessor();
      xsltProcessor.importStylesheet(parser.parseFromString(xsl, "text/xml"));
      const fragment = xsltProcessor.transformToFragment(parser.parseFromString(xmlD, "text/xml"), document);
      rootElement.appendChild(fragment);
      setDoc(rootElement);
    })
  }, []);
  
  
  return (
    <XmlContainer id="xml-container" dangerouslySetInnerHTML={{__html: doc.innerHTML}}>
    </XmlContainer>
  );
}

export default ViewXbrlGlDocumentModal;