/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { ButtonLink, ButtonLinkSmall } from "./InputStyles";
import { AppStateContext } from '../../state/AppStateContext';
import { useTranslation } from 'react-i18next';

const Header: React.FC = () => {
  const navigate = useNavigate();
  const appContext = useContext(AppStateContext);

  const { t, i18n } = useTranslation()

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
      <header>
        <div style={{
          display: 'flex',
          fontWeight: 600,
          justifyContent: "space-between",
        }}>
          <div style={{
            color: tokens.colors.brandBase,
            fontSize: '28px',
          }}>
            {t("xbrl.header.title")}
          </div>

          <div style={{
            display: 'flex',
            textAlign: "right",
            lineHeight: 1
          }}>
            <div style={{
              display: 'flex'
            }}>
              <ButtonLink
                  style={{textTransform: "uppercase", fontSize: '22px', margin: `0 ${tokens.spacing.xxs}`}}
                  onClick={() => changeLanguage('fi') }>
                FI
              </ButtonLink>
              <ButtonLink
                  style={{textTransform: "uppercase", fontSize: '22px', margin: `0 ${tokens.spacing.xxs}`}}
                  onClick={() => changeLanguage('en') }>
                EN
              </ButtonLink>
            </div>
          </div>
        </div>
      </header>
  );
};

export default Header;
